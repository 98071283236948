export const baseUpdateFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        maxlength: 100,
        disabled: true
      }
    },
    // {
    //   field: 'areaPoint',
    //   type: 'input',
    //   label: 'geoFence.area-point'
    // },
    // {
    //   field: 'createdTime',
    //   type: 'input',
    //   label: 'common.app-create-time'
    // },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200
      }
    }
  ],
  validateRules: {}
}
