export const baseViewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'areaPoint',
      type: 'input',
      label: 'geoFence.area-point',
      otherOptions: {
        disabled: true
      }
    },

    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200,
        disabled: true
      }
    }
  ],
  validateRules: {}
}
